.menu {
  @include no-select;
  position: fixed;
  top: $gutter;
  left: $gutter;
  right: $gutter;
  font-size: $font-size--sm;

  pointer-events: none;

  @include mobile {
    display: none;
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    pointer-events: auto;
    text-transform: lowercase;
    margin-right: 2rem;
  }

  // Split the flexbox by creating a flexible space after the second item
  &__item:nth-child(2){
    display: flex;
    flex: 1;

    pointer-events: none;
    a { pointer-events: auto; }

    &::after {
      content: '';
      flex: 1;
      display: inline-block;
    }
  }

  &__item a.is-active {
    font-weight: bold;
  }
}
