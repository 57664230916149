.agenda-entry {
  position: relative;
  padding-left: $gutter / 2;

  & + & {
    margin-top: $gutter / 2;
  }

  p {
    margin: 0 !important;
  }

  &::before {
    position: absolute;
    content: '•';
    left: 0;
  }

  &__date {
    font-size: inherit;
    margin-bottom: 0.25em;
  }

  &__content {
    margin-bottom: 0.2rem;
  }

  &__location {}

  &__infos {
    font-style: italic;
  }
}
