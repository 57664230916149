.submenu {
  @include no-select;
  @include easing(600ms, opacity, true);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: $gutter;
  padding-top: $gutter * 2;

  background: linear-gradient(var(--direction), #{$accent}, #{$white});

  @include mobile {
    display: none;
  }

  &:not(.is-open) {
    opacity: 0;
    pointer-events: none;
  }

  &:focus-within {
    display: block;
    opacity: 1;
  }

  &__items {
    @include invisible-scroll(y);

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: -$gutter / 2;
    min-width: $max-width;
    width: calc(50vw - #{$cover-width / 2} - #{$gutter / 2});
  }

  &[data-direction='to right'] &__items {
    padding-right: $gutter;
    align-items: flex-start;
  }

  &[data-direction='to right'] &__item a {
    margin-left: -$gutter / 4;
    padding-left: $gutter / 4;
    &:hover,
    &:focus {
      transform: translateX(#{$gutter / 4}) skewX(-3deg);
    }
  }

  &[data-direction='to left'] &__items {
    padding-left: $gutter;
    align-items: flex-end;
    float: right;
  }

  &[data-direction='to left'] &__item a {
    margin-right: -$gutter / 4;
    padding-right: $gutter / 4;
    &:hover,
    &:focus {
      transform: translateX(-#{$gutter / 4}) skewX(-3deg);
    }
  }

  &__item {
    font-size: $font-size--lg;
    text-align: left;
    text-align-last: right;

    // Using padding instead of margin so that their is no hoverable gap between
    // two items
    padding-bottom: 0.5em;

    a {
      @include easing(600ms, transform);
      display: block;
      will-change: transform;
    }

    &__year {
      display: inline-block;
      font-style: italic;
      font-size: $font-size;
      vertical-align: middle;
      &::before {
        display: inline;
        content: '—';
      }
    }

    &__cover {
      pointer-events: none;
      position: fixed;
      top: 50%;
      left: 50%;

      opacity: 0;
      transition:
        opacity 300ms linear,
        transform 600ms $cubic-bezier;
      transform-origin: center center;
      will-change: opacity transform;
      transform: translate(-50%, -50%) scale(1.02);

      img {
        width: $cover-width;
        height: auto;
        // max-width: $max-width;
      }
    }

    &:hover &__cover,
    &:focus-within &__cover {
      opacity: 0.7;
      transform: translate(-50%, -50%);
    }
  }
}
