.pswp {
  &__img {
    background: $accent;
    padding: $gutter;

    @include mobile {
      padding: $gutter--sm;
    }
  }

  &__ui {
    -webkit-font-smoothing: unset;
  }

  &__counter {
    color: $black;
    font-family: 'Poppins', sans-serif;
    font-size: $font-size--sm;
  }

  &__bg { background-color: $accent; }
  &__caption {
    @include no-select;
    background-color: $accent !important;
    opacity: 0.9;
    &__center {
      font-family: 'Poppins', sans-serif;
      font-size: $font-size--sm;
      line-height: $line-height;
      text-align: center;
      color: $black !important;
      padding: $gutter;
      @include mobile  {
        padding: $gutter--sm;
      }
    }
  }

  &__button {
    body:not([data-night=true]) & { filter: invert(1); }
    &--arrow--left,
    &--arrow--right {
      margin: 0 $gutter / 2;
    }
  }

  &__top-bar {
    background: none !important;
    opacity: 1 !important;
    display: table !important;
  }
}
