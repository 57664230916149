@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';
@import 'sass/devices';

$black: #232321;
$white: #EBEADD;
$accent: #DDDEEB;
$cubic-bezier: cubic-bezier(.215,.61,.355,1);

$gutter: 5rem;
$gutter--sm: 3rem;

$max-width: 550px;
$cover-width: 50vmin;
$cover--vertical-spacing: 25vh;

$font-size: 1.8rem;
$font-size--sm: 1.5rem;
$font-size--lg: var(--font-size--lg);
$line-height: 1.1;

:root  {
  --font-size--lg: 3.0rem;
  @include mobile { --font-size--lg: 2.4rem; }
}

.page__cover { z-index: 1; }
.submenu, .menu--mobile { z-index: 2; }
.menu, .menu--mobile__open, .footer { z-index: 3; }
.menu:hover, .footer:hover { z-index: 4; }
.menu--mobile__lang { z-index: 5; }
.pswp { z-index: 6; }

@import 'sass/base';

@include desktop {
  @include no-bounce-scroll;
}

@import 'components/AgendaEntry';
@import 'components/Footer';
@import 'components/Home';
@import 'components/Menu';
@import 'components/Menu--mobile';
@import 'components/Page';
@import 'components/Submenu';
@import 'components/Photoswipe';

