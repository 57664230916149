.footer {
  @include no-select;
  position: fixed;
  left: $gutter;
  right: $gutter;
  bottom: $gutter;
  font-size: $font-size--sm;

  display: flex;
  justify-content: space-between;

  pointer-events: none;

  @include mobile {
    display: none;
  }

  &__items {
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    pointer-events: auto;
    text-transform: lowercase;
    margin-right: 2rem;
  }
}
