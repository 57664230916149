#openMobileMenu,
.menu--mobile,
.menu--mobile__open,
.menu--mobile__lang {
  display: none;
}

@include mobile {
  .menu--mobile__open {
    @include no-select;
    cursor: pointer;

    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: ($gutter--sm * 0.75) $gutter--sm;
    font-size: $font-size--sm;

    background: linear-gradient(to bottom, #{$white}, rgba(0, 0, 0, 0.01));
  }

  .menu--mobile__lang {
    display: block;
    position: fixed;
    text-transform: lowercase;
    top: 0;
    right: 0;
    padding: ($gutter--sm * 0.75) $gutter--sm;
    font-size: $font-size--sm;
  }

  #openMobileMenu:checked + .menu--mobile__open {
    font-weight: bold;
    background: linear-gradient(to bottom, #{$accent}, rgba(0, 0, 0, 0.01));
  }

  .menu--mobile {
    @include no-select;
    @include easing(600ms, opacity, true);
    opacity: 0;
    pointer-events: none;

    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #{$accent}, #{$white});

    text-transform: lowercase;

    &__items {
      @include invisible-scroll(y);

      height: 100%;
      padding: $gutter--sm;
      padding-top: ($gutter--sm * 2) + $gutter;

      display: flex;
      align-items: flex-start;
      flex-direction: column;

      & & {
        height: auto;
        padding: 0;
        overflow: auto;
      }
    }

    &__item {
      margin-bottom: 0.5em;
      text-align: left;
      text-align-last: right;
      font-size: $font-size--lg;
    }

    &__item__year {
      display: inline-block;
      font-style: italic;
      font-size: $font-size;
      vertical-align: middle;
      &::before {
        display: inline;
        content: '—';
      }
    }

    &__group {
      order: 100;
      margin-top: $gutter;
    }

    &__group-name {
      font-size: $font-size--sm;
      margin-bottom: 0.5em;
    }
  }

  #openMobileMenu:checked ~ .menu--mobile {
    opacity: 1;
    pointer-events: auto;
  }
}
