.home {
  @include no-select;
  &__cover {
    position: absolute;

    @include desktop {
      left: 50%;
      transform: translate(-50%, -50%);

      top: calc(50vh + #{$cover--vertical-spacing / 2});
      padding-bottom: $cover--vertical-spacing;
      background-image: none !important;

      img {
        width: 100%;
        height: auto;
        width: $cover-width;
      }
    }

    @include mobile {
      top: $gutter--sm * 2;
      left: $gutter--sm;
      right: $gutter--sm;
      bottom: $gutter--sm;

      background-repeat: no-repeat;
      background-size: cover;

      img {
        display: none;
      }
    }
  }
}
