html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Poppins', sans-serif;
  font-size: $font-size;
  line-height: $line-height;
  font-variant-ligatures: none;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: $white;
}

::selection  {
  background: $accent;
  color: $black;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

a {
  text-decoration: none;
  color: inherit;
}
