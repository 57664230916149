.page {
  position: relative;
  margin: 0 auto 33vh;

  @include mobile {
    padding: $gutter--sm;
    padding-top: $gutter--sm * 2;
  }

  &__header {
    @include no-select;
    position: relative;

    @include desktop {
      width: 100vw;
      height: 75vh;
      &.has-cover {
        height: 100vh + $cover--vertical-spacing / 2;
      }
    }
  }

  &__header &__title {
    font-size: Min(#{$cover--vertical-spacing}, var(--font-size));
    white-space: nowrap;

    @include mobile {
      font-size: 6rem;
      white-space: normal;
    }

    @include desktop {
      position: sticky;
      top: calc(50% - 0.5em);
    }

    h1 {
      text-align: center;
      font-size: inherit;
      font-family: 'Le Murmure';
      font-weight: normal;
      text-transform: lowercase;
    }
  }

  &__header &__title &__year {
    position: absolute;
    bottom: calc(#{-$cover--vertical-spacing / 4} + 0.5em);
    left: 0;
    right: 0;

    font-size: $font-size;
    font-family: $gutter;
    font-style: italic;
    text-align: center;
  }

  &__header &__cover {
    @include desktop {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      top: 50vh + $cover--vertical-spacing / 2;
      padding-bottom: $cover--vertical-spacing;
    }

    @include mobile {
      margin-bottom: $gutter--sm;
    }

    img {
      width: 100%;
      height: auto;
      @include desktop {
        width: $cover-width;
      }
    }
  }

  &__content {
    margin: ($gutter * 2) auto;
    max-width: $max-width;
  }

  &__header.has-cover + &__content {
    margin-top: $cover--vertical-spacing / 2;
  }

  &__content &__text {
    h2,
    p,
    ul,
    blockquote,
    figure {
      margin-bottom: 1em;
      line-height: 1.32;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
      text-underline-position: under;
    }

    h2 {
      font-size: $font-size--lg;
      margin-top: $gutter;
      font-weight: normal;
    }

    ul {
      list-style: '•\00a0';
      p { margin: 0; }
    }

    blockquote {}
    blockquote > p {
      font-style: italic;

      &::before {
        display: inline;
        content: '«\00a0';
      }

      &::after {
        display: inline;
        content: '\00a0»';
      }
    }

    blockquote footer {
      font-size: $font-size--sm;
      text-align: right;
      &::before { content: '—'; }
    }

    figure {
      position: relative;
      margin: $gutter 0 $gutter (-$gutter);
      width: calc(100% + #{$gutter * 2});

      @include mobile {
        margin: $gutter--sm 0 $gutter--sm (-$gutter--sm);
        width: 100vw;
      }

      img {
        width: 100%;
      }

      img[data-lazyload] {
        @include easing(600ms, opacity, true);
        opacity: 0;
        &[data-loaded=true] { opacity: 1; }
      }

      &.has-photoswipe {
        cursor: pointer;
        cursor: zoom-in;
      }

      .video__wrapper {
        position: relative;
        height: auto;
        background: black;

        &[data-ratio='1/1'] { padding-bottom: 100%; }
        &[data-ratio='3/2'] { padding-bottom: 66.67%; }
        &[data-ratio='4/3'] { padding-bottom: 75%; }
        &[data-ratio='16/9'] { padding-bottom: 56.25%; }
        &[data-ratio='16/10'] { padding-bottom: 62.5%; }

        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }

      figcaption {
        margin-top: 1em;
      }
    }

    .gallery {
      position: relative;
      margin: 1em 0 1em calc(-#{$gutter} - 0.5em);
      width: calc(100% + #{$gutter * 2} + 1em);

      @include mobile {
        margin: $gutter--sm 0 $gutter--sm (-$gutter--sm);
        width: 100vw;
      }

      &__items {
        display: flex;
        list-style: none;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &[data-cols='4'] li { width: 25%; }
      &[data-cols='3'] li { width: 33.333%; }
      &[data-cols='2'] li { width: 50%; }

      &__item { padding: 0.5em; }
      &__item figure {
        width: 100%;
        margin: 0;
        figcaption {
          display: none;
        }
      }

      .gallery__caption {
        margin-top: -0.5em;
      }
    }

    .gallery__caption, figcaption {
      font-size: $font-size--sm;
      text-align: center;

      @include mobile {
        padding: 0 $gutter--sm;
      }
    }
  }
}
